<template>
  <div class="" style="overflow: hidden">
    <ControlNavbar />
    <div class="container-fluid">
      <div class="category row align-items-center">
        <div
          :class="
            `col-3 col-sm-2 col-md-2 ${
              $session.get('direction') == 'ltr' || !$session.get('direction')
                ? 'category-title'
                : 'category-title-arabic'
            }`
          "
          @click="$router.go(-1)"
        >
          <p>
            <img
              onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
              :src="allData.iconUrl"
              class="img-white"
              :alt="allData.icon"
              width="60px"
            />
          </p>
          <p>{{ allData.label }}</p>
        </div>
        <div class="col-9 col-sm-10 col-md-10">
          <div class="items" style="padding-top: 1%;">
            <slick ref="slick" :options="slickOptions">
              <div
                class=""
                v-for="(item, index) in allData.data.items"
                :key="index"
              >
                <div
                  v-if="item.type == 'category' || item.type == 'listing'"
                  class="item"
                  @click="
                    (e) => {
                      updateListingPreviewData(item, e);
                    }
                  "
                >
                  <p>
                    <img
                      onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
                      :src="item.iconUrl"
                      :alt="item.icon"
                      style="width: 70px"
                      :class="item.tintable ? `img-grey` : ``"
                    />
                  </p>
                  <p style="font-size: 13px">{{ item.label }}</p>
                </div>
                <div v-else>
                  <div class="item" v-if="item && item.type == 'voucher'">
                    <p>
                      <router-link
                        :to="{
                          name: 'Voucher',
                          params: { voucher: item },
                        }"
                      >
                        <img
                          onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
                          @click="
                            (e) => {
                              tintIt(item, e);
                            }
                          "
                          :src="item.iconUrl"
                          :alt="item.icon"
                          style="width: 70px"
                          :class="item.tintable ? `img-grey` : ``"
                        />
                      </router-link>
                    </p>

                    <p style="font-size: 13px">{{ item.label }}</p>
                  </div>

                  <div class="item" v-else-if="item && item.type == 'carfax'">
                    <p>
                      <router-link
                        :to="{
                          name: 'Carfax',
                          params: { carfax: item },
                        }"
                      >
                        <img
                          onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
                          @click="
                            (e) => {
                              tintIt(item, e);
                            }
                          "
                          :src="item.iconUrl"
                          :alt="item.icon"
                          style="width: 70px"
                          :class="item.tintable ? `img-grey` : ``"
                        />
                      </router-link>
                    </p>
                    <p style="font-size: 13px">{{ item.label }}</p>
                  </div>

                  <div class="item" v-else-if="item && item.type == 'app'">
                    <p>
                      <router-link
                        :to="{
                          name: 'SpecificApplication',
                          params: { application: item },
                        }"
                      >
                        <img
                          onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
                          @click="
                            (e) => {
                              tintIt(item, e);
                            }
                          "
                          :src="item.iconUrl"
                          :alt="item.icon"
                          style="width: 70px"
                          :class="item.tintable ? `img-grey` : ``"
                        />
                      </router-link>
                    </p>
                    <p style="font-size: 13px">{{ item.label }}</p>
                  </div>

                  <div
                    class="item"
                    v-else-if="item && item.type == 'dynamicapp'"
                  >
                    <p>
                      <router-link
                        :to="{
                          name: 'DynamicApplication',
                          params: { application: item },
                        }"
                      >
                        <img
                          onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
                          @click="
                            (e) => {
                              tintIt(item, e);
                            }
                          "
                          :src="item.iconUrl"
                          :alt="item.icon"
                          style="width: 70px"
                          :class="item.tintable ? `img-grey` : ``"
                        />
                      </router-link>
                    </p>
                    <p style="font-size: 13px">{{ item.label }}</p>
                  </div>
                  <div class="item" v-else-if="item && item.type == 'bill'">
                    <p>
                      <router-link
                        :to="{
                          name: 'SpecificBill',
                          params: { bill: item },
                        }"
                      >
                        <img
                          onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
                          @click="
                            (e) => {
                              tintIt(item, e);
                            }
                          "
                          :src="item.iconUrl"
                          :alt="item.icon"
                          style="width: 70px"
                          :class="item.tintable ? `img-grey` : ``"
                        />
                      </router-link>
                    </p>
                    <p style="font-size: 13px">{{ item.label }}</p>
                  </div>

                  <div
                    class="item"
                    v-else-if="item && item.type == 'intltopup'"
                  >
                    <p>
                      <router-link
                        :to="{
                          name: 'IntTopup',
                          params: { object: item },
                        }"
                      >
                        <img
                          onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
                          @click="
                            (e) => {
                              tintIt(item, e);
                            }
                          "
                          :src="item.iconUrl"
                          :alt="item.icon"
                          style="width: 70px"
                          :class="item.tintable ? `img-grey` : ``"
                        />
                      </router-link>
                    </p>
                    <p style="font-size: 13px">{{ item.label }}</p>
                  </div>

                  <div
                    class="item"
                    v-else-if="item && item.type == 'parkmeterfine'"
                  >
                    <p>
                      <router-link
                        :to="{
                          name: 'Parkmeter',
                          params: { object: item },
                        }"
                      >
                        <img
                          onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
                          @click="
                            (e) => {
                              tintIt(item, e);
                            }
                          "
                          :src="item.iconUrl"
                          :alt="item.icon"
                          style="width: 70px"
                          :class="item.tintable ? `img-grey` : ``"
                        />
                      </router-link>
                    </p>
                    <p style="font-size: 13px">
                      {{ item.label }}
                    </p>
                  </div>
                  <div class="item" v-else-if="item && item.type == 'phone'">
                    <p>
                      <router-link
                        :to="{
                          name: '',
                          params: { object: item },
                        }"
                      >
                        <img
                          onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
                          @click="
                            (e) => {
                              tintIt(item, e);
                            }
                          "
                          :src="item.iconUrl"
                          :alt="item.icon"
                          style="width: 70px"
                          :class="item.tintable ? `img-grey` : ``"
                        />
                      </router-link>
                    </p>
                    <p style="font-size: 13px">
                      {{ item.label }}
                    </p>
                  </div>
                  <div class="item" v-else-if="item && item.type == 'qr'">
                    <p>
                      <router-link
                        :to="{
                          name: 'QR',
                          params: { object: item },
                        }"
                      >
                        <img
                          onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
                          @click="
                            (e) => {
                              tintIt(item, e);
                            }
                          "
                          :src="item.iconUrl"
                          :alt="item.icon"
                          style="width: 70px"
                          :class="item.tintable ? `img-grey` : ``"
                        />
                      </router-link>
                    </p>
                    <p style="font-size: 13px">{{ item.label }}</p>
                  </div>
                  <div v-else-if="item && item.type == 'link'" class="item">
                    <a :href="item.data.target" target="_blank">
                      <p>
                        <img
                          onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
                          @click="
                            (e) => {
                              tintIt(item, e);
                            }
                          "
                          :src="item.iconUrl"
                          :alt="item.icon"
                          style="width: 100%"
                          :class="item.tintable ? `img-grey` : ``"
                        />
                      </p>
                      <p style="font-size: 13px">
                        {{ item.label }}
                      </p>
                    </a>
                  </div>
                  <div class="item" v-else-if="item && item.type == 'web'">
                    <a :href="item.data.target" target="_blank">
                      <p>
                        <img
                          onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
                          @click="
                            (e) => {
                              tintIt(item, e);
                            }
                          "
                          :src="item.iconUrl"
                          :alt="item.icon"
                          style="width: 100%"
                          :class="item.tintable ? `img-grey` : ``"
                        />
                      </p>
                      <p style="font-size: 13px">
                        {{ item.label }}
                      </p>
                    </a>
                  </div>

                  <div v-else-if="item && item.type == 'price'" class="item">
                    <p>
                      <router-link
                        :to="{
                          name: '',
                          params: { object: item },
                        }"
                      >
                        <img
                          onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
                          @click="
                            (e) => {
                              tintIt(item, e);
                            }
                          "
                          :src="item.iconUrl"
                          :alt="item.icon"
                          style="width: 70px"
                          :class="item.tintable ? `img-grey` : ``"
                        />
                      </router-link>
                    </p>
                    <p style="font-size: 13px">{{ item.label }} price</p>
                  </div>

                  <div v-else-if="item && item.type == 'transfer'" class="item">
                    <p>
                      <router-link
                        :to="{
                          name: 'Transfer',
                          params: { transfer: item },
                        }"
                      >
                        <img
                          onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
                          @click="
                            (e) => {
                              tintIt(item, e);
                            }
                          "
                          :src="item.iconUrl"
                          :alt="item.icon"
                          style="width: 70px"
                          :class="item.tintable ? `img-grey` : ``"
                        />
                      </router-link>
                    </p>
                    <p style="font-size: 13px">
                      {{ item.label }}
                    </p>
                  </div>
                </div>
              </div>
            </slick>
          </div>
        </div>
      </div>
      <div class="row">
        <!-- item itself -->
        <ListingPreview :data="category" />
      </div>
    </div>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
// import axios from "axios";
import Slick from "vue-slick";

// @ is an alias to /src
import ControlNavbar from "@/components/ControlNavbar";
import ListingPreview from "@/components/ListingPreview";

export default {
  name: "SpecificListing",
  components: { ControlNavbar, Slick, ListingPreview },
  data() {
    return {
      slickOptions: {
        rtl: this.$session.get("direction") == "rtl" ? true : false,
        infinite: false,
        arrows: false,
        paddingLeft: "0px",
        paddingRight: "0px",
        marginRight: "0px",
        slidesToShow: 6,
        slidesToScroll: 3,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 10,
              slidesToScroll: 5,
            },
          },
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 7,
              slidesToScroll: 5,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 390,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 2,
            },
          },
        ],
      },
      category: null,
      allData: null,
    };
  },
  methods: {
    tintIt(item, elt) {
      document.getElementsByClassName("tint").forEach((item) => {
        item.classList.remove("tint");
      });
      if (item.tintable) {
        elt.target.classList.add("tint");
      }
    },
    updateListingPreviewData(data, elt) {
      console.log(data);
      document.getElementsByClassName("tint").forEach((item) => {
        item.classList.remove("tint");
      });
      if (data.tintable) {
        elt.firstChild.classList.add("tint");
      }

      // elt.style.color = "red";
      // document.getElementsByClassName("item").forEach((element) => {
      //   // console.log(element);
      //   if (element != elt.target.parentElement)
      //     element.classList.remove("clicked");
      // });
      elt.target.className += " mystyle";
      console.log(elt.target.parentElement);

      this.category = data;
    },
    next() {
      this.$refs.slick.next();
    },

    prev() {
      this.$refs.slick.prev();
    },
    reInit() {
      // Helpful if you have to deal with v-for to update dynamic lists
      this.$nextTick(() => {
        this.$refs.slick.reSlick();
      });
    },
  },
  created() {
    console.log("listing: ", this.$route.params.category);
    console.log("all", this.$route.params.all);
    if (this.$route.params.category && this.$route.params.all) {
      this.$session.set("backup_cat", this.$route.params.category);
      this.$session.set("backup_all", this.$route.params.all);
      this.category = this.$route.params.category;
      this.allData = this.$route.params.all;
    } else {
      this.category = this.$session.get("backup_cat");
      this.allData = this.$session.get("backup_all");
    }
  },
};
</script>

<style lang="scss">
.img-grey {
  filter: grayscale(1);
}
.tint {
  filter: grayscale(1) contrast(100%) invert(30%) sepia(73%) saturate(4519%)
    hue-rotate(336deg) brightness(92%) contrast(97%);
}

.clicked {
  p {
    color: #e62946 !important;
  }
}

.slick-track {
  margin-left: 0px !important;
}
</style>

<style lang="scss" scoped>
.slick-slide img {
  display: inherit;
}

.category-title {
  cursor: pointer;

  p {
    margin-top: 7px;
    margin-bottom: 0px;
  }

  background: #e62946;
  color: white;
  border-top-right-radius: 45px;
  border-bottom-right-radius: 45px;
  // padding: 0.25% 1.5% 0.25% 1%;
  // width: fit-content !important;
  // max-width: fit-content;
  // min-width: fit-content;
  font-size: 60%;
  width: 50px;
  height: 100px;
  max-width: 120px;
  width: 80px;
  min-width: 80px;

  img {
    width: 50px;
    height: 50px;
    object-fit: scale-down;
  }
}

.category-title-arabic {
  cursor: pointer;

  p {
    margin-top: 7px;
    margin-bottom: 0px;
  }

  background: #e62946;
  color: white;
  border-top-left-radius: 45px;
  border-bottom-left-radius: 45px;
  // padding: 0.25% 1.5% 0.25% 1%;
  // width: fit-content !important;
  // max-width: fit-content;
  // min-width: fit-content;
  font-size: 60%;
  width: 50px;
  height: 100px;
  max-width: 120px;
  width: 80px;
  min-width: 80px;

  img {
    width: 50px;
    height: 50px;
    object-fit: scale-down;
  }
}

.category {
  padding-top: 1%;
  box-shadow: 0px 7px 5px grey;
}

.item {
  text-align: -webkit-center;
}

.items {
  a {
    text-decoration: none;
    color: black;
  }

  .item {
    margin-right: 10px !important;
    height: 12vh;

    img {
      cursor: pointer;
      padding: 5px;
      margin: auto;
      object-fit: scale-down;
      height: 60px;
    }
  }
}
</style>
