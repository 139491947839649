var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"preview"},[(_vm.data.data.items[0].type == 'category')?_c('div',{staticClass:"header-category"},[_c('slick',{ref:"slick",attrs:{"options":_vm.slickOptions}},_vm._l((_vm.data.data.items),function(item,index){return _c('div',{key:(index + "_" + (item.type)),staticClass:"category"},[_c('img',{staticClass:"country-categories",attrs:{"onerror":"this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`","id":index,"src":item.iconUrl,"alt":"item.icon","width":"70px"},on:{"click":function (e) {
              _vm.previewVouchers(item);
              _vm.tintIt(e);
            }}}),_c('br'),_c('p',[_vm._v(_vm._s(item.label))])])}),0)],1):_vm._e(),_c('br'),_c('div',{staticClass:"category-products"},[(_vm.categoryData)?_c('div',{staticClass:"row"},_vm._l((_vm.categoryData),function(item,index){return _c('div',{key:index,class:("" + (item ? 'col-6 col-sm-6 col-md-4 col-lg-4' : ''))},[(item && item.type == 'voucher')?_c('div',{staticClass:"zoom"},[_c('router-link',{attrs:{"to":{
              name: 'Voucher',
              params: { voucher: item },
            }}},[_c('p',[_c('img',{attrs:{"onerror":"this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`","src":item.data.image,"alt":item.icon,"width":"80%"}})]),_c('p',{staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(item.label))])])],1):(item && item.type == 'phone')?_c('div',{staticClass:"zoom"},[_c('router-link',{attrs:{"to":{
              name: 'Phone',
              params: { phone: item },
            }}},[_c('p',[_c('img',{attrs:{"onerror":"this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`","src":item.iconUrl,"alt":item.icon,"width":"20%"}})]),_c('p',{staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(item.label))])])],1):(item && item.type == 'carfax')?_c('div',{staticClass:"zoom"},[_c('router-link',{attrs:{"to":{
              name: 'Carfax',
              params: { carfax: item },
            }}},[_c('img',{staticStyle:{"width":"70px"},attrs:{"onerror":"this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`","src":item.iconUrl,"alt":item.icon}}),_c('p',{staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(item.label)+" ")])])],1):(item && item.type == 'app')?_c('div',{staticClass:"zom"},[_c('router-link',{attrs:{"to":{
              name: 'SpecificApplication',
              params: { application: item },
            }}},[_c('img',{staticStyle:{"width":"70px"},attrs:{"onerror":"this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`","src":item.iconUrl,"alt":item.icon}}),_c('p',{staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(item.label))])])],1):(item && item.type == 'dynamicapp')?_c('div',{staticClass:"zom"},[_c('router-link',{attrs:{"to":{
              name: 'DynamicApplication',
              params: { application: item },
            }}},[_c('img',{staticStyle:{"width":"70px"},attrs:{"onerror":"this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`","src":item.iconUrl,"alt":item.icon}}),_c('p',{staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(item.label))])])],1):(item && item.type == 'bill')?_c('div',{staticClass:"zom"},[_c('router-link',{attrs:{"to":{
              name: 'SpecificBill',
              params: { bill: item },
            }}},[_c('img',{staticStyle:{"width":"70px"},attrs:{"onerror":"this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`","src":item.iconUrl,"alt":item.icon}}),_c('p',{staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(item.label)+" bill")])])],1):(item && item.type == 'intltopup')?_c('div',{staticClass:"zom"},[_c('router-link',{attrs:{"to":{
              name: 'IntTopup',
              params: { object: item },
            }}},[_c('img',{staticStyle:{"width":"70px"},attrs:{"onerror":"this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`","src":item.iconUrl,"alt":item.icon}}),_c('p',{staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(item.label))])])],1):(item && item.type == 'parkmeterfine')?_c('div',{staticClass:"zom"},[_c('router-link',{attrs:{"to":{
              name: 'Parkmeter',
              params: { object: item },
            }}},[_c('img',{staticStyle:{"width":"70px"},attrs:{"onerror":"this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`","src":item.iconUrl,"alt":item.icon}}),_c('p',{staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(item.label)+" ")])])],1):(item && item.type == 'qr')?_c('div',{staticClass:"zom"},[_c('router-link',{attrs:{"to":{
              name: 'QR',
              params: { object: item },
            }}},[_c('img',{staticStyle:{"width":"70px"},attrs:{"onerror":"this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`","src":item.iconUrl,"alt":item.icon}}),_c('p',{staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(item.label))])])],1):(item && item.type == 'link')?_c('div',{staticClass:"zom"},[_c('a',{attrs:{"href":item.data.target,"target":"_blank"}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"onerror":"this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`","src":item.iconUrl,"alt":item.icon}})]),_c('p',{staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(item.label)+" ")])]):(item && item.type == 'web')?_c('div',{staticClass:"zom"},[_c('a',{attrs:{"href":item.data.target,"target":"_blank"}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"onerror":"this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`","src":item.iconUrl,"alt":item.icon}}),_c('p',{staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(item.label)+" ")])])]):(item && item.type == 'price')?_c('div',{staticClass:"zom"},[_c('router-link',{attrs:{"to":{
              name: '',
              params: { object: item },
            }}},[_c('img',{staticStyle:{"width":"70px"},attrs:{"onerror":"this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`","src":item.iconUrl,"alt":item.icon}}),_c('p',{staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(item.label)+" type: price")])])],1):(item && item.type == 'transfer')?_c('div',{staticClass:"zom"},[_c('router-link',{attrs:{"to":{
              name: 'Transfer',
              params: { transfer: item },
            }}},[_c('img',{staticStyle:{"width":"70px"},attrs:{"onerror":"this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`","src":item.iconUrl,"alt":item.icon}}),_c('p',{staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(item.label))])])],1):_vm._e()])}),0):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }