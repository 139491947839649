<template>
  <div class="preview" style="">
    <div class="header-category" v-if="data.data.items[0].type == 'category'">
      <slick ref="slick" :options="slickOptions">
        <div
          class="category"
          v-for="(item, index) in data.data.items"
          :key="`${index}_${item.type}`"
        >
          <img
            onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
            :id="index"
            class="country-categories"
            :src="item.iconUrl"
            alt="item.icon"
            width="70px"
            @click="
              (e) => {
                previewVouchers(item);
                tintIt(e);
              }
            "
          />
          <br />
          <p>{{ item.label }}</p>
        </div>
      </slick>
    </div>
    <br />
    <div class="category-products">
      <div class="row" v-if="categoryData">
        <div
          v-for="(item, index) in categoryData"
          :key="index"
          :class="`${item ? 'col-6 col-sm-6 col-md-4 col-lg-4' : ''}`"
        >
          <div v-if="item && item.type == 'voucher'" class="zoom">
            <router-link
              :to="{
                name: 'Voucher',
                params: { voucher: item },
              }"
            >
              <p>
                <img
                  onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
                  :src="item.data.image"
                  :alt="item.icon"
                  width="80%"
                />
              </p>
              <p style="font-size: 13px">{{ item.label }}</p>
            </router-link>
          </div>
          <div class="zoom" v-else-if="item && item.type == 'phone'">
            <router-link
              :to="{
                name: 'Phone',
                params: { phone: item },
              }"
            >
              <p>
                <img
                  onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
                  :src="item.iconUrl"
                  :alt="item.icon"
                  width="20%"
                />
              </p>
              <p style="font-size: 13px">{{ item.label }}</p>
            </router-link>
          </div>

          <div class="zoom" v-else-if="item && item.type == 'carfax'">
            <router-link
              :to="{
                name: 'Carfax',
                params: { carfax: item },
              }"
            >
              <img
                onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
                :src="item.iconUrl"
                :alt="item.icon"
                style="width: 70px"
              />
              <p style="font-size: 13px">
                {{ item.label }}
              </p>
            </router-link>
          </div>
          <div class="zom" v-else-if="item && item.type == 'app'">
            <router-link
              :to="{
                name: 'SpecificApplication',
                params: { application: item },
              }"
            >
              <img
                onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
                :src="item.iconUrl"
                :alt="item.icon"
                style="width: 70px"
              />
              <p style="font-size: 13px">{{ item.label }}</p>
            </router-link>
          </div>

          <div class="zom" v-else-if="item && item.type == 'dynamicapp'">
            <router-link
              :to="{
                name: 'DynamicApplication',
                params: { application: item },
              }"
            >
              <img
                onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
                :src="item.iconUrl"
                :alt="item.icon"
                style="width: 70px"
              />
              <p style="font-size: 13px">{{ item.label }}</p>
            </router-link>
          </div>

          <div class="zom" v-else-if="item && item.type == 'bill'">
            <router-link
              :to="{
                name: 'SpecificBill',
                params: { bill: item },
              }"
            >
              <img
                onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
                :src="item.iconUrl"
                :alt="item.icon"
                style="width: 70px"
              />
              <p style="font-size: 13px">{{ item.label }} bill</p>
            </router-link>
          </div>

          <div class="zom" v-else-if="item && item.type == 'intltopup'">
            <router-link
              :to="{
                name: 'IntTopup',
                params: { object: item },
              }"
            >
              <img
                onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
                :src="item.iconUrl"
                :alt="item.icon"
                style="width: 70px"
              />
              <p style="font-size: 13px">{{ item.label }}</p>
            </router-link>
          </div>

          <div class="zom" v-else-if="item && item.type == 'parkmeterfine'">
            <router-link
              :to="{
                name: 'Parkmeter',
                params: { object: item },
              }"
            >
              <img
                onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
                :src="item.iconUrl"
                :alt="item.icon"
                style="width: 70px"
              />
              <p style="font-size: 13px">
                {{ item.label }}
              </p>
            </router-link>
          </div>

          <div class="zom" v-else-if="item && item.type == 'qr'">
            <router-link
              :to="{
                name: 'QR',
                params: { object: item },
              }"
            >
              <img
                onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
                :src="item.iconUrl"
                :alt="item.icon"
                style="width: 70px"
              />
              <p style="font-size: 13px">{{ item.label }}</p>
            </router-link>
          </div>

          <div class="zom" v-else-if="item && item.type == 'link'">
            <a :href="item.data.target" target="_blank">
              <img
                onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
                :src="item.iconUrl"
                :alt="item.icon"
                style="width: 100%"
              />
            </a>
            <p style="font-size: 13px">
              {{ item.label }}
            </p>
          </div>

          <div class="zom" v-else-if="item && item.type == 'web'">
            <a :href="item.data.target" target="_blank">
              <img
                onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
                :src="item.iconUrl"
                :alt="item.icon"
                style="width: 100%"
              />
              <p style="font-size: 13px">
                {{ item.label }}
              </p>
            </a>
          </div>

          <div class="zom" v-else-if="item && item.type == 'price'">
            <router-link
              :to="{
                name: '',
                params: { object: item },
              }"
            >
              <img
                onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
                :src="item.iconUrl"
                :alt="item.icon"
                style="width: 70px"
              />
              <p style="font-size: 13px">{{ item.label }} type: price</p>
            </router-link>
          </div>

          <div class="zom" v-else-if="item && item.type == 'transfer'">
            <router-link
              :to="{
                name: 'Transfer',
                params: { transfer: item },
              }"
            >
              <img
                onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
                :src="item.iconUrl"
                :alt="item.icon"
                style="width: 70px"
              />
              <p style="font-size: 13px">{{ item.label }}</p>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import Slick from "vue-slick";

// @ is an alias to /src
// import ControlNavbar from "@/components/ControlNavbar";

export default {
  name: "ListingPreview",
  props: {
    data: {
      required: true,
    },
  },
  components: { Slick },
  data() {
    return {
      slickShow: true,
      slickOptions: {
        rtl: this.$session.get("direction") == "rtl" ? true : false,
        infinite: false,
        arrows: false,
        paddingLeft: "0px",
        paddingRight: "0px",
        marginRight: "2px",
        slidesToShow: 6,
        slidesToScroll: 3,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 10,
              slidesToScroll: 5,
            },
          },
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 7,
              slidesToScroll: 5,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 390,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 2,
            },
          },
        ],
      },
      items: null,
      categoryData: this.data.data.items,
    };
  },
  watch: {
    data: {
      handler: function(newData) {
        console.log("data changed", newData);
        let resetSlick = true;
        if (this.data == newData) {
          resetSlick = true;
        }
        this.categoryData = newData.data.items;
        setTimeout(function() {
          if (document.getElementById("0")) {
            console.log(document.getElementById("0"));
            document.getElementById("0").click();
          }
        }, 500);
        if (resetSlick && this.$refs.slick != undefined) {
          let currIndex = this.$refs.slick.currentSlide();

          this.$refs.slick.destroy();
          this.$nextTick(() => {
            if (this.$refs.slick) {
              this.$refs.slick.create();
              this.$refs.slick.goTo(currIndex, true);
            }
          });
        }
      },
    },
  },
  methods: {
    next() {
      this.$refs.slick.next();
    },

    prev() {
      this.$refs.slick.prev();
    },
    reInit() {
      // Helpful if you have to deal with v-for to update dynamic lists
      this.$nextTick(() => {
        this.$refs.slick.reSlick();
      });
    },
    tintIt(elt) {
      elt = elt.target;
      document.getElementsByClassName("clicked-p").forEach((item) => {
        item.classList.remove("clicked-p");
      });
      elt.parentElement.classList.add("clicked-p");
    },
    previewVouchers(catData) {
      console.log("preview", this.data);

      this.categoryData = catData.data.items;
      // setTimeout(() => {
      //   this.reInit();
      // }, 6000);
    },
  },
  created() {
    this.categoryData = this.data.data.items;
    setTimeout(function() {
      if (document.getElementById("0")) {
        document.getElementById("0").click();
      }
    }, 500);
  },
};
</script>

<style lang="scss" scoped>
.country-categories {
  cursor: pointer;
}
.preview {
  width: 100%;
}
.slick-slide img {
  display: inherit;
}
.category-title {
  background: #e62946;
  border-radius: 0px 45px 45px 0px;
  color: white;
}
.header-category {
  padding: 20px;
  background: #e6e6e6;
  box-shadow: inset 0px 1px 5px 3px grey;
}
.category {
  img {
    object-fit: scale-down;
    height: 50px;
  }
}
a {
  color: black;
}
.listing-item:hover {
  img {
    transform: scale(1.25);
    margin-bottom: 10px;
    margin-top: 10px;
  }
}
.zoom {
  transition: transform 0.2s;
}
.zoom:hover {
  -ms-transform: scale(1.15); /* IE 9 */
  -webkit-transform: scale(1.15); /* Safari 3-8 */
  transform: scale(1.15);
  z-index: 999;
  margin-bottom: 10px;
  filter: drop-shadow(0px 1px 6px grey);
}
.clicked-p {
  color: #e62946;
  img {
    filter: drop-shadow(2px 2px 5px black);
  }
}
div.category-products {
  a {
    img {
      height: 150px;
      object-fit: scale-down;
    }
  }
}
</style>
